<template>
  <div>
    <h5 class="mb-3">{{ $t('sparkfunnels.analytics.stage-conversion') }}</h5>
    <div class="card b-shadow" ref="graph">
      <vue-funnel-graph
        v-if="width && hasValue"
        :width="width"
        :height="height"
        :labels="labels"
        :values="values"
        :colors="colors"
        :direction="direction"
        :gradient-direction="gradientDirection"
        :animated="true"
        :display-percentage="true"
      ></vue-funnel-graph>
      <div v-else :style="{ width: width + 'px', height: height + 'px' }" class="d-flex justify-content-between">
        <div v-for="(label, index) in labels" :key="index" :class="`w-100 flex-column no-values-div index${index}`">
          <div class="no-values-details">-</div>
          <div class="no-values-label">{{ label }}</div>
          <div class="no-values">-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueFunnelGraph } from 'vue-funnel-graph-js';

export default {
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueFunnelGraph,
  },
  data() {
    return {
      labels: [],
      values: [],
      colors: ['#FF2F5C', '#AC7DF7'],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 300,
      width: 0,
    };
  },
  mounted() {
    this.width = this.$refs.graph.clientWidth;
  },
  created() {
    this.funnel.stages.forEach(stage => {
      this.labels.push(stage.name);
      this.values.push(stage.completed_lead_stage_count);
    });
  },
};
</script>

<style lang="scss">
$card-padding-top: 20px;

.b-shadow {
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(38, 38, 38, 0.05);
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title,
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
  color: $grey;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 16px;
  font-weight: initial;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
  font-size: 40px;
  line-height: 2.4rem;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  color: $cyan;
  font-size: 16px;
  letter-spacing: -0.27px;
  line-height: 20px;
}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
  padding-top: $card-padding-top;
}
.no-values-div {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: $card-padding-top;
  border-left: 1px solid #9896dc;
  .no-values {
    font-size: 40px;
    font-weight: bold;
  }
  .no-values-label {
    font-size: 16px;
    min-height: 48px;
  }
  .no-values-details {
    color: $cyan;
    font-size: 16px;
    letter-spacing: -0.27px;
    line-height: 20px;
  }
}
.index0 {
  border: none;
}
</style>
